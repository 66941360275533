const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'General',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/app/dashboard',
                    icon: 'feather icon-home'
                },
                {
                    id: 'apis',
                    title: 'Settings',
                    type: 'item',
                    url: '/app/settings',
                    icon: 'feather icon-cloud'
                }
                /*{
                    id: 'funnels',
                    title: 'Funnels',
                    type: 'collapse',
                    icon: 'fas fa-newspaper',
                    children: [
                        {
                            id: 'all_funnels',
                            title: 'All Funnels',
                            type: 'item',
                            url: '/app/funnels'
                        },
                        {
                            id: 'add_funnels',
                            title: 'Add New Funnel',
                            type: 'item',
                            url: '/app/funnels/add'
                        }
                    ]
                }*/
            ]
        },
        {
            id: 'all-reports',
            title: 'REPORTS',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'report',
                    title: 'Report',
                    type: 'item',
                    url: '/app/report',
                    icon: 'feather icon-pie-chart'
                },
                {
                    id: 'creativeReport',
                    title: 'Creative Report',
                    type: 'item',
                    url: '/app/creative-report',
                    icon: 'feather icon-pie-chart'
                },
                {
                    id: 'contacts',
                    title: 'Contacts',
                    type: 'collapse',
                    icon: 'fas fa-hdd',
                    children: [
                        {
                            id: 'leads',
                            title: 'Leads',
                            type: 'item',
                            url: '/app/leads'
                        },
                        {
                            id: 'booked_calls',
                            title: 'Booked Calls',
                            type: 'item',
                            url: '/app/calls'
                        }
                    ]
                }
            ]
        },
        {
            id: 'ui-others',
            title: 'OTHER Inactive',
            type: 'group',
            icon: 'icon-ui',
            children: [
                {
                    id: 'team-members',
                    title: 'Team Members',
                    type: 'item',
                    url: '/app/team',
                    icon: 'fas fa-users'
                },
                {
                    id: 'profile',
                    title: 'My Profile',
                    type: 'item',
                    url: '/app/profile',
                    icon: 'far fa-address-card'
                }
            ]
        }
    ]
};

export default menuItems;
