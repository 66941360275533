import React, { useState, useEffect } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import useWindowSize from '../../../../hooks/useWindowSize';
import NavSearch from './NavSearch';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { SELECT_BUSINESS } from './../../../../store/actions';
import { API_SERVER } from './../../../../config/constant';

const NavLeft = () => {
    const windowSize = useWindowSize();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [businesses, setBusinesses] = useState([]);
    // Add this near your other useState declarations
    const [searchTerm, setSearchTerm] = useState('');
    //========================================
    const dispatcher = useDispatch();
    const history = useHistory();
    const handleSelectBusiness = (id) => {
        dispatcher({
            type: SELECT_BUSINESS,
            payload: { selectedBusinessId: id }
        });
        history.push('/app/settings');
        setDropdownOpen(false);
    };
    //=========================================
    const fetchBusinesses = () => {
        try {
            axios
                .get(API_SERVER + 'business/all')
                .then(function (response) {
                    if (response.data) {
                        //console.log(response.data);
                        setBusinesses(response.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchBusinesses();
    }, []);

    let dropdownRightAlign = false;

    let navItemClass = ['nav-item'];
    if (windowSize.width <= 575) {
        navItemClass = [...navItemClass, 'd-none'];
    }

    return (
        <React.Fragment>
            <ListGroup as="ul" bsPrefix=" " className="navbar-nav mr-auto">
                <ListGroup.Item as="li" bsPrefix=" " className={navItemClass.join(' ')}>
                    <Dropdown alignRight={dropdownRightAlign} show={dropdownOpen} onToggle={setDropdownOpen}>
                        <Dropdown.Toggle variant={'link'} id="dropdown-basic">
                            Select Business Space
                        </Dropdown.Toggle>
                        <ul>
                            <Dropdown.Menu>
                                <div className="px-3 py-2">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search business..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </div>
                                {businesses
                                    .filter((business) => business.active && business.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                    .slice()
                                    .reverse()
                                    .map((business) => (
                                        <li key={business.id}>
                                            <Link
                                                to="#"
                                                className={`dropdown-item ${business.id}`}
                                                onClick={() => handleSelectBusiness(business.id)}
                                            >
                                                {business.name}
                                            </Link>
                                        </li>
                                    ))}
                            </Dropdown.Menu>
                        </ul>
                    </Dropdown>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" " className="nav-item">
                    <NavSearch windowWidth={windowSize.width} />
                </ListGroup.Item>
            </ListGroup>
        </React.Fragment>
    );
};

export default NavLeft;
